import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

export const guestRouteList: IRouteItem[] = [
  {
    name: "Reprint",
    path: "/reprint",
    LazyComponent: lazy(() => import("../pages/Reprint/Reprint")),
  },
  {
    name: "QR",
    path: "/qrcode",
    LazyComponent: lazy(() => import("../pages/Reprint/Reprint")),
  },
  {
    name: "terms_and_conditions",
    path: "/terms-and-conditions",
    LazyComponent: lazy(
      () => import("../pages/TermsAndConditions/TermsAndConditions")
    ),
  },
  {
    name: "print",
    path: "/print/:id",
    LazyComponent: lazy(() => import("../pages/Print/Print")),
    exact: true,
  },

  {
    name: "policy_wording",
    path: "/policy-wording",
    LazyComponent: lazy(() => import("../pages/PolicyWording/PolicyWording")),
  },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, Key) =>
    routerDom.push(
      <Route
        key={Key}
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const guestRoutes = parseRoutes(guestRouteList);
